<template>
  <div>
    <section class="bg-half-260 sect">
      <div class="bg-overlay" style="opacity: 0.45"></div>
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="title-heading">
              <h4 class="display-4 mb-4 font-weight-bold text-white title-dark">
                Puedes realizar <br />
                tus <span class="text-primary">Solicitudes</span> aquí
              </h4>
              <p class="para-desc text-white-50">
                Con nuestro sistema de accesos directos a Solicitudes té
                ofrecemos una manera más rápida de realizar tus peticiones
                guiándote a través de nuestra web hacia los formularios de
                destino
              </p>
              <div class="mt-4 pt-2">
                <a href="javascript:void(0)" class="btn btn-primary"
                  >Contactar !</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section bg-light">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="features-absolute rounded bg-white shadow">
              <div class="row">
                <div class="col-lg-8 col-md-12 my-4">
                  <div class="row">
                    <div
                      class="col-md-6 col-12"
                      v-for="(card, idx) in props.cards"
                      v-bind:key="idx"
                    >
                      <div class="media features p-4">
                        <div
                          class="
                            icon
                            text-center
                            rounded-circle
                            text-primary
                            mr-3
                            mt-2
                          "
                        >
                          <i v-bind:class="card.icon"></i>
                        </div>
                        <div class="media-body">
                          <h4 class="title">{{ card.title }}</h4>
                          <p class="text-muted para mb-0">
                            {{ card.description }}
                          </p>
                          <div class="mt-2">
                            <a href="" v-if="card.btnTxt" class="text-primary"
                              >{{ card.btnTxt }} <i class="mdi mdi-chevron-right mr-1"></i>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="col-lg-4 d-none d-lg-block position-absolute dogI"
                    >
                      <img
                        src="@/assets/construction/portada.png"
                        alt=""
                        class="img-fluid my-auto pet-img"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section>
      <div class="container mt-3 mb-100">
        <div class="row align-items-center">
          <div class="col-lg-5 col-md-6">
            <img
              src="@/assets/construction/beagle.jpg"
              alt=""
              class="img-fluid rounded"
            />
          </div>
          <div class="col-lg-7 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
            <div class="section-title ml-lg-5">
              <h4 class="title mb-4">
                <span class="font-weight-light">Te ofrecemos estos </span>
                <span class="text-primary font-weight-normal">Servicios </span>
                <span class="font-weight-light">adicionales</span>
              </h4>
              <h5 class="text-primary font-weight-normal">
                <i class="uil uil-arrow-circle-right text-primary mr-1"></i>
                Aplica como Intermediario
              </h5>
              <p class="text-muted para-desc ml-4 pl-2">
                {{props.tab2[0].description}} <i class="mdi mdi-chevron-right mr-1 text-primary" /> <a href="" class="text-primary font-weight-light">Ir al formulario</a>
              </p>
              <h5 class="text-primary font-weight-normal">
                <i class="uil uil-arrow-circle-right text-primary mr-1"></i>
                Aplicar como proveedor
              </h5>
              <p class="text-muted para-desc ml-4 pl-2">
                {{props.tab3[0].description}} <i class="mdi mdi-chevron-right mr-1 text-primary" /> <a href="" class="text-primary font-weight-light">Ir al formulario</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  props: {
    props: Object,
  },
};
</script>

<style>
body {
  font-family: "Nunito", sans-serif;
  overflow-x: hidden !important;
  font-size: 16px;
  color: #161c2d;
}

.mb-100 {
    margin-bottom: 100px;
}

.borde {
  border: 1px solid green;
}

.pet-img {
  position: absolute;
  bottom: 0;
  right: 0px;
}

.dogI {
  margin-left: 770px;
  margin-top: 280px;
}

.sect {
  background: url("../../assets/construction/dog4.jpg") center center;
  background-size: cover !important;
  height: 87vh;
  position: relative;
  margin: none;
}

.bg-overlay {
  background-color: rgba(60, 72, 88, 0.7);
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}
</style>